/* .MuiTreeItem-content {
  background-color: blanchedalmond;
}

.MuiTreeItem-content.Mui-selected {
  background-color: blue;
} */



.footer-entering {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.footer-entered {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.capitalize {
  text-transform: capitalize !important;
  font-weight: 700 !important;
}

.selectLabel {
  top: 10px;
  position: relative;
  z-index: 99;
  left: 8px;
  background: white;
  font-weight: 200;
  padding-left: 5px;
  padding-right: 5px;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
}

/* #html5qr-code-full-region {
  height: 500px;
}

#qr-shaded-region {
  height: 500px;
}

#video {
  height:'500px';
} */

video {
  max-Height: 75vh; 
}


  .navbar {
    position: sticky;
    top: 0;
    left: 0;
    z-Index: 99;
    right: 0;
    display: block;
    z-index: 100;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #f3f3f3; /* or any background color you prefer */
    /* Add any other navbar styles you want */
  }

  /* root: {
    position: "sticky",
    top: "0em",
    zIndex: "99",
    display: "block",
    alignItems: "center",
    background: "#ffffff",
    // borderBottom:'1px solid #ecebeb;'
    // boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 10px 0px',
  }, */

  /* QrCodeImage.css */
.qr-code-image {
  display: none;
}

@media print {
  .qr-code-image {
    display: block;
  }
}

